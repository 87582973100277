<template>
    <div
        id="404"
        data-cy="404message"
        class="d-flex align-start flex-column mb-6"
    >
        <base-heading title="404" class="mx-auto mt-5" />

        <div class="text-h5 mx-auto pb-5"
            >Looks like this page doesn't exist..</div
        >

        <div class="mx-auto">
            <base-btn :to="{ name: 'home' }">
                Get me Out of Here
            </base-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: `FourOhFour`
}
</script>
